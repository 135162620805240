import React from 'react'
import { Link } from 'gatsby'

const PageNotFound = () => {
	return (
		<section className="page-not-found">
			<h1>404! Stefan was not found... :(</h1>
			<Link to="/">Startsida</Link>
		</section>
	)
}

export default PageNotFound